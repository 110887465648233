import React, { useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { Button, Grid, Stack, Typography } from '@mui/material';

// HOOKS & API
import { useGetAttendanceDatesQuery } from 'services/private/attendance';

// COMPONENTS & UTILS
import { FormikField, FormikSelect } from 'shared/form';
import SubmitBtn from 'shared/form/SubmitBtn';
import FormikTextEditor from 'shared/form/FormikModernTextEditor';
import useAuthInfo from 'customHooks/useAuthInfo';
import {
  dailyReportFormInitialSchema,
  dailyReportFormInitialValue,
} from '../utilities/formUtils';

function DailyReportForm({ responseHandler, attendanceID, afterRequestSend, formData, handler }) {
  const { company } = useAuthInfo();
  const [initValues, setInitValues] = useState(dailyReportFormInitialValue);
  const { data: attendanceDate } = useGetAttendanceDatesQuery();

  const modifiedDate = useMemo(() => {
    if (attendanceDate) {
      const options = attendanceDate?.map(opt => ({
        label: opt?.date,
        value: opt?.id,
      }));
      return options;
    }

    return [];
  }, [attendanceDate]);

  const handleFormSubmit = async values => {
    let resp;
    if (formData?.project) {
      resp = await handler({
        productive_hours: +values.productive_hours || 0,
        mins: +values.mins || 0,
        project: values.project,
        report: values.report,
        attendance: formData.attendance,
        id: formData.id,
        company: company?.id
      });
    } else {
      resp = await handler({
        ...values,
        productive_hours: +values.productive_hours || 0,
        mins: +values.mins || 0,
        attendance: attendanceID,
        company: company?.id
      });
    }
    responseHandler(resp);
  };

  useEffect(() => {
    if (formData.project) {
      setInitValues({
        project: formData.project,
        productive_hours: formData.productive_hours,
        mins: formData.mins,
        report: formData.report,
        checked_in_date: formData.attendance,
      });
    } else {
      setInitValues(prevValue => ({
        ...prevValue,
        checked_in_date: modifiedDate[0]?.value,
      }));
    }
  }, [modifiedDate, formData]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={dailyReportFormInitialSchema}
      onSubmit={async values => handleFormSubmit(values)}
    >
      {({ isSubmitting }) => (
        <Form className="d-flex align-items-start flex-column gap-3">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="label" className="required">
                Checked In Date
              </Typography>
              <FormikSelect
                name="checked_in_date"
                placeholder="Select Date"
                options={modifiedDate}
              />
            </Grid>

            <Grid sx={{ zIndex: 50 }} item xs={12} md={6}>
              <Typography className="required" variant="label">
                Project
              </Typography>
              <FormikField placeholder="Project" name="project" />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="label">Hours</Typography>
              <FormikField placeholder="Hours" type="number" name="productive_hours" />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="required" variant="label">
                Minutes
              </Typography>
              <FormikField placeholder="Minutes" type="number" name="mins" />
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Typography variant="label" className="required">
                Report
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormikTextEditor name="report" isRequired />
            </Grid>
          </Grid>

          <Stack direction="row" mt={2} spacing={1}>
            <SubmitBtn label="Submit" isLoading={isSubmitting} />

            <Button variant="mutedColor" onClick={afterRequestSend}>
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

DailyReportForm.defaultProps = {
  afterRequestSend: () => null,
  responseHandler: () => {},
  handler: () => {},
  attendanceID: null,
  formData: {},
};

DailyReportForm.propTypes = {
  afterRequestSend: propTypes.func,
  responseHandler: propTypes.func,
  handler: propTypes.func,
  attendanceID: propTypes.number,
  formData: propTypes.object,
};

export default DailyReportForm;
