import useAuthInfo from 'customHooks/useAuthInfo';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetForgetCheckOutQuery } from 'services/private/attendance';

function ForgetSubmitReportRoute() {
  const { company } = useAuthInfo();
  const { data: ForgetCheckOut } = useGetForgetCheckOutQuery({ company: company?.id });
  return ForgetCheckOut && !ForgetCheckOut?.report_submitted ? (
    <Navigate to="attendance/daily-tasks-report" replace />
  ) : (
    <Outlet />
  );
}

export default ForgetSubmitReportRoute;
