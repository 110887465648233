import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  account: null,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomerData: (state, action) => ({
      ...state,
      account: action.payload,
    }),
    removeCustomerData: state => ({
      ...state,
      account: null,
    })
  },
});

export const { getCustomerData, removeCustomerData } = customerSlice.actions;
export default customerSlice.reducer;
