import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormHelperText, Grid, Typography } from '@mui/material';
import theme from 'styles/mui/theme';
import useGetThemeColor from 'customHooks/useGetThemeColor';

const customStyles = primaryColor => ({
  option: (styles, state) => ({
    ...styles,
    paddingTop: '5px',
    paddingBottom: '5px',
    fontSize: '12px',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    backgroundColor: state.isSelected ? primaryColor : styles.backgroundColor,
    color: state.isSelected ? theme.palette.common.white : styles.color,
    '&:hover': {
      backgroundColor: state.isSelected ? primaryColor : styles.backgroundColor,
      color: state.isSelected ? theme.palette.common.white : styles.color,
    },
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? `2px solid ${primaryColor} !important` : `1px solid ${provided.borderColor}`,
    '&:hover': {
      borderColor: state.isFocused ? `2px solid ${primaryColor} !important` : `1px solid ${provided.borderColor}`,
    },
  }),
  groupHeading: provided => ({
    ...provided,
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#000000',
  }),
  menu: provided => ({
    ...provided,
    minWidth: '200px',
  }),
});

function selectedValue(options, value) {
  let filtered = options.find(option => {
    if (typeof option.label === 'object') {
      return false;
    }
    if (option.options) {
      return option.options.find(item => item.value === value);
    }
    return option.value === value;
  });

  if (filtered && filtered.options) {
    filtered = filtered.options.find(item => item.value === value);
  }

  return filtered;
}

function GroupedOptionsFormikSelect({
  onBlur,
  onChange,
  name,
  options,
  touched,
  value,
  error,
  placeholder,
  menuPosition,
  disabled,
  label,
  classes,
  isRow,
  isRequired
}) {
  const primaryColor = useGetThemeColor();

  const handleChange = tempValue => {
    if (onChange) onChange(name, tempValue.value);
  };

  const handleBlur = () => {
    if (onBlur) onBlur(name, true);
  };

  // console.log('selectedValue(options, value) ==> ', selectedValue(options, value), options, value);

  return (
    <Grid className={classes} spacing={1} container>
      <Grid item className="d-flex align-items-center" xl={isRow ? 3 : 12} lg={isRow ? 3 : 12} md={isRow ? 4 : 12} sm={12}>
        {label && (
          <Typography
            className={isRequired ? 'required' : ''}
            variant="body2"
            sx={{ mb: '2px !important' }}
          >
            {label}
          </Typography>
        )}
      </Grid>
      <Grid item xl={isRow ? 9 : 12} lg={isRow ? 9 : 12} md={isRow ? 8 : 12} sm={12}>
        <Select
          id="color"
          options={options}
          multi
          onChange={handleChange}
          onBlur={handleBlur}
          value={selectedValue(options, value)}
          placeholder={placeholder}
          classNamePrefix="react-select"
          isDisabled={disabled}
          styles={customStyles(primaryColor)}
          menuPosition={menuPosition}
        />
        {touched && error && (
          <FormHelperText>{error}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

GroupedOptionsFormikSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  menuPosition: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  classes: PropTypes.string,
  isRow: PropTypes.bool,
  isRequired: PropTypes.bool,
};

GroupedOptionsFormikSelect.defaultProps = {
  touched: false,
  error: null,
  placeholder: null,
  menuPosition: null,
  disabled: false,
  label: null,
  classes: '',
  isRow: false,
  isRequired: false,
  onChange: null,
  onBlur: null,
};

export default GroupedOptionsFormikSelect;
