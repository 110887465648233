import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: localStorage.getItem('user'),
  isAuthenticated: false,
  isLoading: false,
  companyDetail: {},
  getCompanyDetail: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLoggedIn: (state, action) => {
      const userDetails = { ...action.payload };
      localStorage.setItem('user', JSON.stringify(action.payload.user));

      return {
        ...state,
        isAuthenticated: true,
        user: userDetails,
      };
    },
    onLoggedOut: () => {
      localStorage.removeItem('user');
      localStorage.removeItem('companyDetail');
      return { ...initialState };
    },
    getCompanyDetail: (state, action) => {
      const company = { ...action.payload };
      localStorage.setItem('companyDetail', JSON.stringify(action.payload));
      return { ...state, companyDetails: company };
    },
    userLoaded: (state, action) => {
      const res = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: res,
      };
    },
  },
});

export const { onLoggedIn, onLoggedOut, getCompanyDetail, userLoaded } =
  authSlice.actions;
export default authSlice.reducer;
