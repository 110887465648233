import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import useAuthInfo from 'customHooks/useAuthInfo';
import { mainContainerStyles } from 'styles/mui/containers/layoutStyles';
import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';

function LayoutWrapper() {
  const { isAuthenticated } = useAuthInfo();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const handleToggleSidebar = () => {
    setSidebarOpen(prevState => !prevState);
  };

  return (
    isAuthenticated && (
      <Box>
        <Topbar isOpen={isSidebarOpen} handleToggleSidebar={handleToggleSidebar} />

        <Box className="d-flex align-items-start w-100">
          <Sidebar isOpen={isSidebarOpen} handleToggleSidebar={handleToggleSidebar} />
          {/* <Box> */}
          <Container sx={mainContainerStyles(isSidebarOpen)} className="p-1">
            <Outlet />
          </Container>
          {/* </Box> */}
        </Box>
      </Box>
    )
  );
}

export default LayoutWrapper;
