import React from 'react';
import { useMediaQuery, Drawer, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { sidebarCollapse, sidebarExpand } from 'styles/containers/wrapper';
import SidebarContent from './SidebarContent';

function Sidebar({ isOpen, handleToggleSidebar }) {
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box>
      {!isMobile ? (
        <Box style={!isOpen ? sidebarExpand : sidebarCollapse}>
          <SidebarContent isOpen={isOpen} handleToggleSidebar={handleToggleSidebar} />
        </Box>
      ) : (
        <Drawer
          sx={sidebarExpand}
        >
          <SidebarContent isOpen={isOpen} handleToggleSidebar={handleToggleSidebar} />
        </Drawer>
      )}
    </Box>
  );
}

export default Sidebar;
Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
};
