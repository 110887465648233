/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import Dashboard from 'containers/pages/dashboard';
import DailyReports from 'containers/pages/attendance/dailyReports';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import ForgetSubmitReportRoute from './ForgetSubmitReportRoute';
import ForgetCheckoutRoute from './ForgetCheckoutRoute';
import OperationsRolesRoute from './OperationsRolesRoute';

const LogIn = lazy(() => import('containers/pages/auth/login'));
const ForgotPassword = lazy(() => import('containers/pages/auth/forgotPassword'));
const ChangePassword = lazy(() => import('containers/pages/auth/changePassword/index'));

// Items

const ItemsMainPage = lazy(() => import('containers/pages/accounting/items/index'));
const ItemsDetailPage = lazy(() => import('containers/pages/accounting/items/components/detail'));
const AddEditItemForm = lazy(() => import('containers/pages/accounting/items/components/form'));

// Items

const InventoryAdjustment = lazy(() => import('containers/pages/accounting/inventoryAdjustment/index'));
const InventoryAdjustmentDetailPage = lazy(() => import('containers/pages/accounting/inventoryAdjustment/components/detail'));
const AddEditInventoryAdjustmentForm = lazy(() => import('containers/pages/accounting/inventoryAdjustment/components/form'));

// sale/Acounts

const AccountsMainPage = lazy(() => import('containers/pages/accounting/sales/accounts'));
const AddEditAccountsForm = lazy(() => import('containers/pages/accounting/sales/accounts/components/form'));
const AccountsDetailPage = lazy(() => import('containers/pages/accounting/sales/accounts/components/detail'));

// sale/customer

const CustomersMainPage = lazy(() => import('containers/pages/accounting/sales/customers'));
const AddEditCustomersForm = lazy(() => import('containers/pages/accounting/sales/customers/components/form'));
const CustomerDetailPage = lazy(() => import('containers/pages/accounting/sales/customers/components/detail'));

// sale/quotation

const QuotationsMainPage = lazy(() => import('containers/pages/accounting/sales/quotations'));
const QuotationDetailPage = lazy(() => import('containers/pages/accounting/sales/quotations/components/detail'));
const AddEditQuotationForm = lazy(() => import('containers/pages/accounting/sales/quotations/components/form'));

// sale/proInvoice
const ProformaInvoiceMainPage = lazy(() => import('containers/pages/accounting/sales/proformaInvoices'));
const ProInvoiceDetailPage = lazy(() => import('containers/pages/accounting/sales/proformaInvoices/components/detail'));
const AddEditProInvoiceFrom = lazy(() => import('containers/pages/accounting/sales/proformaInvoices/components/form'));

// sale/Invoice

const AddEditInvoicePage = lazy(() => import('containers/pages/accounting/sales/invoices/components/form'));
const InvoiceDetailPage = lazy(() => import('containers/pages/accounting/sales/invoices/components/detail'));
const InvoiceMainPage = lazy(() => import('containers/pages/accounting/sales/invoices'));

// credit/notes
const CreditNoteDetailPage = lazy(() => import('containers/pages/accounting/sales/creditNotes/detail'));
const AddEditCreditNotePage = lazy(() => import('containers/pages/accounting/sales/creditNotes/components/form'));
const CreditNotesMainPage = lazy(() => import('containers/pages/accounting/sales/creditNotes'));

// sale/payment
const PaymentReceivedDetail = lazy(() => import('containers/pages/accounting/sales/paymentsReceived/components/detail'));
const AddEditPaymentReceivedPage = lazy(() => import('containers/pages/accounting/sales/paymentsReceived/components/form'));
const PaymentReceivedMainPage = lazy(() => import('containers/pages/accounting/sales/paymentsReceived'));

// Banking

const BankingMainPage = lazy(() => import('containers/pages/accounting/banking'));
const BankingFormPage = lazy(() => import('containers/pages/accounting/banking/components/form'));
const BankingImportStatementPage = lazy(() => import('containers/pages/accounting/banking/components/import'));
const BankAccountTransactions = lazy(() => import('containers/pages/accounting/banking/components/detail'));

// purchases
// supplier
const SupplierDetail = lazy(() => import('containers/pages/accounting/purchases/suppliers/components/detail'));
const SupplierMainPage = lazy(() => import('containers/pages/accounting/purchases/suppliers'));
const AddEditSupplierPage = lazy(() => import('containers/pages/accounting/purchases/suppliers/components/form'));

// Expenses
const ExpensesMainPage = lazy(() => import('containers/pages/accounting/purchases/expenses'));
const ExpensesDetailPage = lazy(() => import('containers/pages/accounting/purchases/expenses/component/detail'));
const AddExpense = lazy(() => import('containers/pages/accounting/purchases/expenses/component/form'));
// purchaseOrder
const PurchaseOrderMainPage = lazy(() => import('containers/pages/accounting/purchases/purchaseOrder'));
const PurchaseOrderDetail = lazy(() => import('containers/pages/accounting/purchases/purchaseOrder/component/detail'));
const AddEditPurchaseOrderPage = lazy(() => import('containers/pages/accounting/purchases/purchaseOrder/component/form'));

// bills
const BillsMainPage = lazy(() => import('containers/pages/accounting/purchases/bills'));
const BillDetailPage = lazy(() => import('containers/pages/accounting/purchases/bills/component/detail'));
const AddEditBillPage = lazy(() => import('containers/pages/accounting/purchases/bills/component/form'));
// payment made
const PaymentsMadeMainPage = lazy(() => import('containers/pages/accounting/purchases/paymentsMade'));
const PaymentMadeDetailPage = lazy(() => import('containers/pages/accounting/purchases/paymentsMade/component/detail'));
const AddPaymentMadePage = lazy(() => import('containers/pages/accounting/purchases/paymentsMade/component/Add'));
// debit notes
const DebitNotesMainPage = lazy(() => import('containers/pages/accounting/purchases/debitNotes'));
const DebitNoteDetailPage = lazy(() => import('containers/pages/accounting/purchases/debitNotes/component/detail'));
const AddDebitNotePage = lazy(() => import('containers/pages/accounting/purchases/debitNotes/component/form'));
// Contacts

const ContactsAddEdit = lazy(() => import('containers/pages/accounting/contacts/form'));

// Email

const SendEmail = lazy(() => import('containers/pages/accounting/email'));
// Activity
const ManageActivities = lazy(() => import('containers/pages/Miscellaneous/ManageActivities'));
// Salary
// const Salaries = lazy(() => import('containers/pages/HR/Salaries'));
// opening Balance
const OpeningBalance = lazy(() => import('containers/pages/accounting/openingBalance'));
const OpeningBalanceEdit = lazy(() => import('containers/pages/accounting/openingBalance/Edit'));
//
const StampAndSignature = lazy(() => import('containers/pages/accounting/StampAndSignature'));
// accessibilty
const UserAccessibilityMainPage = lazy(() => import('containers/pages/accounting/userAccessibility'));
const UserAccessibilityFormPage = lazy(() => import('containers/pages/accounting/userAccessibility/components/form'));
// department
const DepartmentMainPage = lazy(() => import('containers/pages/HR/departments'));
const RoleMainPage = lazy(() => import('containers/pages/HR/role'));
const PermissionForm = lazy(() => import('containers/pages/HR/role/components/form/components/Permission'));
// accountant/currencies
const CurrenciesMainPage = lazy(() => import('containers/pages/accounting/accountant/currencies'));
const AddCurrenciesPage = lazy(() => import('containers/pages/accounting/accountant/currencies/components/form'));

// accountant/Transaction locking
const TransactionLocking = lazy(() => import('containers/pages/accounting/accountant/transactionLocking'));
// accountant/Tax payments
const TaxPayments = lazy(() => import('containers/pages/accounting/accountant/taxPayments'));
// accountant/Manual journals
const ManualJournel = lazy(() => import('containers/pages/accounting/accountant/manualJournal'));
const AddEditManualJournel = lazy(() => import('containers/pages/accounting/accountant/manualJournal/components/form'));
const DetailManualJournel = lazy(() => import('containers/pages/accounting/accountant/manualJournal/components/detail'));
// accountant/Manual journals
const ChartOfAccounts = lazy(() => import('containers/pages/accounting/accountant/chartOfAccounts'));

// employees
const HREmployees = lazy(() => import('containers/pages/HR/Employees'));
const HRAddEmployees = lazy(() => import('containers/pages/HR/Employees/components/form'));
const HREmployeesDetail = lazy(() => import('containers/pages/HR/Employees/components/detail'));

const ChartOfAccountDetail = lazy(() => import('containers/pages/accounting/accountant/chartOfAccounts/components/detail'));
// Report
// payable
const ReportSupplierBalancePage = lazy(() => import('containers/pages/reports/components/payables/SupplierBalance'));
const ReportSupplierBillBalanceReport = lazy(() => import('containers/pages/reports/components/payables/SupplierBillBalance'));
const ReportSupplierExcessPaymentBalanceReport = lazy(() => import('containers/pages/reports/components/payables/SupplierExcessPaymentBalance'));
const ReportSupplierBalanceDetailReport = lazy(() => import('containers/pages/reports/components/payables/SupplierBalanceDetail'));
const ReportApAgingSummaryPage = lazy(() => import('containers/pages/reports/components/payables/ApAgingSummary'));
const ReportApAgingDetailPage = lazy(() => import('containers/pages/reports/components/payables/ApAgingDetail'));
const ReportBillDetailsPage = lazy(() => import('containers/pages/reports/components/payables/BillDetails'));
const ReportPayableDebitNoteDetailPage = lazy(() => import('containers/pages/reports/components/payables/PayableDebitNoteDetail'));
const ReportPaymentsMadeDetailPage = lazy(() => import('containers/pages/reports/components/payables/PaymentsMade'));
const ReportPurchaseOrderDetailPage = lazy(() => import('containers/pages/reports/components/payables/PurchaseOrderDetail'));
const ReportPurchaseOrderBySupplierPage = lazy(() => import('containers/pages/reports/components/payables/PurchaseOrderBySupplier'));
const ReportPayableSummaryPage = lazy(() => import('containers/pages/reports/components/payables/PayableSummary'));
const ReportPayableDetailsPage = lazy(() => import('containers/pages/reports/components/payables/PayableDetails'));
const ReportSupplierRefundHistoryPage = lazy(() => import('containers/pages/reports/components/payables/SupplierRefundHistory'));
// receivables
const ReportReceivableAccountBalancePage = lazy(() => import('containers/pages/reports/components/receivables/ReceivableAccountBalance'));
const ReportReceivablesInvoiceBalanceAgainstCustomerPage = lazy(() => import(
  'containers/pages/reports/components/receivables/ReceivablesInvoiceBalanceAgainstCustomer'
));
const ReportReceivablesCreditNoteBalanceAgainstCustomerPage = lazy(() => import(
  'containers/pages/reports/components/receivables/ReceivablesCreditNoteBalanceAgainstCustomer'
));
const ReportReceivableBalanceDetailAgainstCustomerPage = lazy(() => import(
  'containers/pages/reports/components/receivables/ReceivableBalanceDetailAgainstCustomer'
));
const ReportReceivableARAgingSummaryPage = lazy(() => import('containers/pages/reports/components/receivables/ReceivableARAgingSummary'));
const ReportReceivableARAgingDetailPage = lazy(() => import('containers/pages/reports/components/receivables/ReceivableARAgingDetail'));
const ReportReceivableInvoiceDetailPage = lazy(() => import('containers/pages/reports/components/receivables/ReceivableInvoiceDetail'));
const ReportReceivableSummaryPage = lazy(() => import('containers/pages/reports/components/receivables/ReceivableSummary'));
const ReportReceivableDetailsPage = lazy(() => import('containers/pages/reports/components/receivables/ReceivableDetails'));

// tax
const ReportTaxReturnReportPage = lazy(() => import('containers/pages/reports/components/tax/TaxReturnReport'));
const ReportTaxReturnDetailInformationReportPage = lazy(() => import('containers/pages/reports/components/tax/TaxReturnDetailInformationReport'));
const ReportVATAuditReportPage = lazy(() => import('containers/pages/reports/components/tax/VATAuditReport'));
const ReportTaxReturnDetailReportPage = lazy(() => import('containers/pages/reports/components/tax/TaxReturnDetailReport'));

// activity
const ActivityLogsReportPage = lazy(() => import('containers/pages/reports/components/activity/ActivityLogs'));
const ExceptionReport = lazy(() => import('containers/pages/reports/components/activity/ExceptionReport'));

// purchase and expenses
const ReportPurchaseBySupplierPage = lazy(() => import('containers/pages/reports/components/purchase-and-expenses/PurchaseBySupplier'));
const ReportPurchaseBySupplierDetailPage = lazy(() => import(
  'containers/pages/reports/components/purchase-and-expenses/PurchaseBySupplierDetail'
));
const ReportPurchaseByItemPage = lazy(() => import('containers/pages/reports/components/purchase-and-expenses/PurchaseByItem'));
const ReportPurchaseByItemDetailPage = lazy(() => import('containers/pages/reports/components/purchase-and-expenses/PurchaseByItemDetail'));
const ReportExpenseDetailsPage = lazy(() => import('containers/pages/reports/components/purchase-and-expenses/ExpenseDetails'));
const ReportExpenseByCategoryPage = lazy(() => import('containers/pages/reports/components/purchase-and-expenses/ExpenseByCategory'));
const ReportExpenseByCategoryDetailPage = lazy(() => import(
  'containers/pages/reports/components/purchase-and-expenses/ExpenseByCategoryDetail'
));
const PurchaseByClientType = lazy(() => import('containers/pages/reports/components/purchase-and-expenses/PurchaseByClientType'));

// sales Report
const ReportSalesByCustomerPage = lazy(() => import('containers/pages/reports/components/sales/SalesByCustomer'));
const ReportSalesByCustomerDetailPage = lazy(() => import('containers/pages/reports/components/sales/SalesByCustomerDetail'));
const ReportSalesByItemPage = lazy(() => import('containers/pages/reports/components/sales/SalesByItem'));
const ReportSalesByItemDetailPage = lazy(() => import('containers/pages/reports/components/sales/SalesByItemDetail'));
const ReportSalesBySalesPersonPage = lazy(() => import('containers/pages/reports/components/sales/SalesBySalesPerson'));
const ReportSalesBySalesPersonDetailPage = lazy(() => import('containers/pages/reports/components/sales/SalesBySalePersonDetail'));
const ReportSalesBySalesTypePage = lazy(() => import('containers/pages/reports/components/sales/SalesBySalesType'));

// financial report
const ProfitAndLossStatementReportPage = lazy(() => import('containers/pages/reports/components/financial-reports/ProfitAndLossStatement'));
const CashFlowStatementReportPage = lazy(() => import('containers/pages/reports/components/financial-reports/cash-flow-statement'));
const BalanceSheetStatementReportPage = lazy(() => import('containers/pages/reports/components/financial-reports/balance-sheet-statement'));
const MovementOfEquity = lazy(() => import('containers/pages/reports/components/financial-reports/MovementOfEquity'));
const ReportsList = lazy(() => import('containers/pages/reports/components/listing'));

// Miscellaneous
const MiscellaneousSalaries = lazy(() => import('containers/pages/Miscellaneous/employeeSalaries/index'));
const AccountTransaction = lazy(() => import('containers/pages/reports/components/accountant/AccountTransaction'));
const SalesBySalesType = lazy(() => import('containers/pages/reports/components/sales/SalesBySalesType'));
const BussinessPerformanceRatio = lazy(() => import(
  'containers/pages/reports/components/financial-reports/BussinessPerformanceRatio'
));

// receipt voucher
const ReportReceiptVoucherPage = lazy(() => import('containers/pages/reports/components/payment-received/PaymentReceivedReport'));
const ReportCreditNoteDetailPage = lazy(() => import('containers/pages/reports/components/payment-received/CreditNoteDetail'));
const ReportRefundHistoryPage = lazy(() => import('containers/pages/reports/components/payment-received/RefundHistory'));

// accountant
const ReportAccountTransactionPage = lazy(() => import('containers/pages/reports/components/accountant/AccountTransaction'));
const ReportAccountTypeSummaryPage = lazy(() => import('containers/pages/reports/components/accountant/AccountTypeSummary'));
const ReportGeneralLedgerPage = lazy(() => import('containers/pages/reports/components/accountant/GeneralLedger'));
const ReportDetailGeneralLedgerPage = lazy(() => import('containers/pages/reports/components/accountant/DetailGeneralLedger'));
const ReportJournalReportPage = lazy(() => import('containers/pages/reports/components/accountant/JournalReport'));
const ReportTrialBalancePage = lazy(() => import('containers/pages/reports/components/accountant/trial-balance'));
// inventry
const InventrySummryReport = lazy(() => import('containers/pages/reports/components/inventry/InventrySummary'));
const CommittedStockDetailReport = lazy(() => import('containers/pages/reports/components/inventry/CommittedStockDetail'));
const InventryValuationSummaryReport = lazy(() => import('containers/pages/reports/components/inventry/InventryValuationSummary'));
const InventryValuationSummaryDetailReport = lazy(() => import('containers/pages/reports/components/inventry/InventryValuationSummaryDetail'));
const FifoCostTracking = lazy(() => import('containers/pages/reports/components/inventry/FifoCostTracking'));
const InventryAgingSummryReport = lazy(() => import('containers/pages/reports/components/inventry/InventryAgingSummary'));
const ProductSalesReport = lazy(() => import('containers/pages/reports/components/inventry/ProductSalesReport'));
const StockSummaryReport = lazy(() => import('containers/pages/reports/components/inventry/StockSummaryReport'));
const AbcClassificationReport = lazy(() => import('containers/pages/reports/components/inventry/AbcClassification'));
// User profile
const UserProfile = lazy(() => import('containers/pages/user/profile'));

// Shifts
const Shifts = lazy(() => import('containers/pages/attendance/shift'));

// Attendance
const Attendance = lazy(() => import('containers/pages/attendance'));
const TimeSummary = lazy(() => import('containers/pages/attendance/timeSummary'));
const ProductivityReport = lazy(() => import('containers/pages/attendance/productivityReport'));
const EmployeeAttendance = lazy(() => import('containers/pages/attendance/employeeAttendance'));
const ReportRequest = lazy(() => import('containers/pages/attendance/reportRequest'));
// HR
// Company Holidays

const CompanyHolidaysMainPage = lazy(() => import('containers/pages/HR/companyHolidays/index'));
const AddEditHolidayPage = lazy(() => import('containers/pages/HR/companyHolidays/components/form/index'));

// Leaves

const LeavesRequestsMainPage = lazy(() => import('containers/pages/HR/leaveRequests/index'));

// Annoucements

const AnnoucementsMainPage = lazy(() => import('containers/pages/HR/announcements/index'));
const AnnoucementsDetailPage = lazy(() => import('containers/pages/HR/announcements/components/detail/Index'));

// Annoucements

const SalariesMainPage = lazy(() => import('containers/pages/HR/Salaries/index'));

// Documents Requests

const DocRequestsMainPage = lazy(() => import('containers/pages/HR/employeeDocumentsRequests/index'));
// company
const CompanyFilePage = lazy(() => import('containers/pages/HR/comapny/CompanyFiles'));
// storage
const StorageFilePage = lazy(() => import('containers/pages/HR/storage'));

// Storage

const TrashDetailPage = lazy(() => import('containers/pages/Miscellaneous/trash'));

// Meetings

const MeetingsMainPage = lazy(() => import('containers/pages/Miscellaneous/meetings'));

// Test

const ErrorPage = lazy(() => import('containers/common/components/SomethingWentWrongUi'));
// company Profile
const CompanyProfileMainPage = lazy(() => import('containers/pages/HR/comapny/companyProfile'));
const CompanyProfilePage = lazy(() => import('containers/pages/HR/comapny/companyProfile/component/form/index'));

// AssetLogs

const AssetLogsMainPage = lazy(() => import('containers/pages/HR/assetLogs'));
const AssetLogsDetailPage = lazy(() => import('containers/pages/HR/assetLogs/components/detail'));

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader isLoading />}>
        <Routes>
          <Route path="/auth" element={<PublicRoutes />}>
            <Route path="sign-in" element={<LogIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ChangePassword />} />
          </Route>

          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<ForgetSubmitReportRoute />}>
              <Route index element={<Dashboard />} />
              <Route path="/" element={<ForgetCheckoutRoute />}>
                <Route path="reports" element={<Outlet />}>
                  <Route path="" element={<OperationsRolesRoute />}>
                    <Route path="" element={<ReportsList />} />
                    <Route path="supplier-balances" element={<Outlet />}>
                      <Route path="" index element={<ReportSupplierBalancePage />} />
                      <Route
                        path="bill/detail"
                        element={<ReportSupplierBillBalanceReport />}
                      />
                      <Route
                        path="excess-payment/detail"
                        element={<ReportSupplierExcessPaymentBalanceReport />}
                      />
                      <Route
                        path="detail"
                        element={<ReportSupplierBalanceDetailReport />}
                      />
                      {/* <Route
                  path="excess-payment/detail"
                  element={<ReportSupplierExcessPaymentBalanceReport />}
                />
                <Route path="detail" element={<ReportSupplierBalanceDetailReport />} /> */}
                    </Route>
                    <Route path="sales-by-sales-type" element={<Outlet />}>
                      <Route path="" index element={<SalesBySalesType />} />
                    </Route>
                    <Route
                      path="ap-aging-summary"
                      element={<ReportApAgingSummaryPage />}
                    />
                    <Route
                      path="ap-aging-details"
                      element={<ReportApAgingDetailPage />}
                    />
                    <Route path="bill-details" element={<ReportBillDetailsPage />} />
                    <Route
                      path="debit-note"
                      element={<ReportPayableDebitNoteDetailPage />}
                    />
                    <Route
                      path="payments-made"
                      element={<ReportPaymentsMadeDetailPage />}
                    />
                    <Route
                      path="purchase-order-details"
                      element={<ReportPurchaseOrderDetailPage />}
                    />
                    <Route
                      path="purchase-order-by-supplier"
                      element={<ReportPurchaseOrderBySupplierPage />}
                    />
                    <Route
                      path="payable-summary"
                      element={<ReportPayableSummaryPage />}
                    />
                    <Route
                      path="payable-details"
                      element={<ReportPayableDetailsPage />}
                    />
                    <Route
                      path="supplier-refund-history"
                      element={<ReportSupplierRefundHistoryPage />}
                    />
                    <Route path="customer-balances" element={<Outlet />}>
                      <Route
                        path=""
                        index
                        element={<ReportReceivableAccountBalancePage />}
                      />
                      <Route
                        path="sale-invoice/detail"
                        index
                        element={<ReportReceivablesInvoiceBalanceAgainstCustomerPage />}
                      />
                      <Route
                        path="credit-notes/detail"
                        index
                        element={
                          <ReportReceivablesCreditNoteBalanceAgainstCustomerPage />
                        }
                      />
                      <Route
                        path="detail"
                        index
                        element={<ReportReceivableBalanceDetailAgainstCustomerPage />}
                      />
                    </Route>
                    <Route
                      path="ar-aging-summary"
                      element={<ReportReceivableARAgingSummaryPage />}
                    />
                    <Route
                      path="ar-aging-details"
                      element={<ReportReceivableARAgingDetailPage />}
                    />
                    <Route
                      path="invoice-details"
                      element={<ReportReceivableInvoiceDetailPage />}
                    />
                    <Route
                      path="receivable-summary"
                      element={<ReportReceivableSummaryPage />}
                    />
                    <Route
                      path="receivable-details"
                      element={<ReportReceivableDetailsPage />}
                    />
                    <Route path="tax-returns" element={<ReportTaxReturnReportPage />} />
                    <Route
                      path="tax-returns/:id"
                      element={<ReportTaxReturnDetailReportPage />}
                    />
                    <Route
                      path="tax-returns/:id/detail"
                      element={<ReportTaxReturnDetailInformationReportPage />}
                    />
                    <Route path="vat-audit" element={<ReportVATAuditReportPage />} />
                    <Route
                      path="activity-logs"
                      index
                      element={<ActivityLogsReportPage />}
                    />
                    <Route path="exception-report" index element={<ExceptionReport />} />
                    <Route
                      path="purchases-by-supplier"
                      element={<ReportPurchaseBySupplierPage />}
                    />
                    <Route
                      path="purchases-by-supplier/:id/detail"
                      element={<ReportPurchaseBySupplierDetailPage />}
                    />
                    <Route
                      path="purchases-by-item"
                      element={<ReportPurchaseByItemPage />}
                    />
                    <Route
                      path="purchases-by-client-type"
                      element={<PurchaseByClientType />}
                    />

                    <Route
                      path="purchases-by-item/detail"
                      element={<ReportPurchaseByItemDetailPage />}
                    />
                    <Route
                      path="expense-details"
                      element={<ReportExpenseDetailsPage />}
                    />
                    <Route
                      path="expenses-by-category"
                      element={<ReportExpenseByCategoryPage />}
                    />
                    <Route
                      path="expenses-by-category/detail"
                      element={<ReportExpenseByCategoryDetailPage />}
                    />
                    <Route
                      path="sales-by-customer"
                      element={<ReportSalesByCustomerPage />}
                    />
                    <Route
                      path="sales-by-customer/:id/detail"
                      element={<ReportSalesByCustomerDetailPage />}
                    />
                    <Route path="sales-by-item" element={<ReportSalesByItemPage />} />
                    <Route
                      path="sales-by-item/detail"
                      element={<ReportSalesByItemDetailPage />}
                    />
                    <Route
                      path="sales-by-sales-person"
                      element={<ReportSalesBySalesPersonPage />}
                    />
                    <Route
                      path="sales-by-sales-person/detail"
                      element={<ReportSalesBySalesPersonDetailPage />}
                    />
                    <Route
                      path="sales-by-sales-type"
                      element={<ReportSalesBySalesTypePage />}
                    />
                    <Route
                      path="profit-loss"
                      element={<ProfitAndLossStatementReportPage />}
                    />
                    <Route
                      path="cash-flow-statement"
                      element={<CashFlowStatementReportPage />}
                    />
                    <Route
                      path="balance-sheet"
                      element={<BalanceSheetStatementReportPage />}
                    />
                    <Route
                      path="accountTransactions/:id/detail"
                      element={<AccountTransaction />}
                    />
                    <Route path="movementOfEquity" element={<MovementOfEquity />} />
                    <Route
                      path="businessPerformanceRatios"
                      element={<BussinessPerformanceRatio />}
                    />

                    <Route
                      path="payment-received"
                      element={<ReportReceiptVoucherPage />}
                    />
                    <Route
                      path="credit-note-detail"
                      element={<ReportCreditNoteDetailPage />}
                    />
                    <Route path="refund-history" element={<ReportRefundHistoryPage />} />
                    <Route
                      path="account-transactions"
                      element={<ReportAccountTransactionPage />}
                    />
                    <Route
                      path="account-type-summary"
                      element={<ReportAccountTypeSummaryPage />}
                    />
                    <Route path="general-ledger" element={<ReportGeneralLedgerPage />} />
                    <Route
                      path="detailed-general-ledger"
                      element={<ReportDetailGeneralLedgerPage />}
                    />
                    <Route path="journal-report" element={<ReportJournalReportPage />} />
                    <Route path="trial-balance" element={<ReportTrialBalancePage />} />
                    <Route path="inventory-summary" element={<InventrySummryReport />} />
                    <Route
                      path="committed-stock-details"
                      element={<CommittedStockDetailReport />}
                    />
                    <Route
                      path="inventory-valuation-summary"
                      element={<InventryValuationSummaryReport />}
                    />
                    <Route
                      path="inventory-valuation-summary/:id/detail"
                      element={<InventryValuationSummaryDetailReport />}
                    />
                    <Route path="fifo-cost-lot-tracking" element={<FifoCostTracking />} />
                    <Route
                      path="inventory-aging-summary"
                      element={<InventryAgingSummryReport />}
                    />
                    <Route path="product-sales-report" element={<ProductSalesReport />} />
                    <Route path="stock-summary-report" element={<StockSummaryReport />} />
                    <Route
                      path="abc-classification"
                      element={<AbcClassificationReport />}
                    />
                  </Route>
                </Route>
                <Route path="accounting" element={<Outlet />}>
                  {/* <Route path="salaries" element={<Salaries />} /> */}

                  <Route path="opening-balance" element={<Outlet />}>
                    <Route path="setup" element={<OpeningBalanceEdit />} />
                    <Route path="" element={<OpeningBalance />} />
                  </Route>
                  <Route path="accessibility" element={<Outlet />}>
                    <Route path=":id/edit" element={<UserAccessibilityFormPage />} />
                    <Route path="" element={<UserAccessibilityMainPage />} />
                  </Route>
                  <Route path="department" element={<Outlet />}>
                    <Route path="" element={<DepartmentMainPage />} />
                  </Route>
                  <Route path="role" element={<Outlet />}>
                    <Route path="" element={<RoleMainPage />} />
                    <Route path="permission/:id" element={<PermissionForm />} />

                  </Route>
                  <Route path="stemp-signature" element={<Outlet />}>
                    <Route path="" element={<StampAndSignature />} />
                  </Route>

                  <Route path="inventoryAdjustments" element={<Outlet />}>
                    <Route index element={<InventoryAdjustment />} />
                    <Route path="add" element={<AddEditInventoryAdjustmentForm />} />
                    <Route path=":id/edit" element={<AddEditSupplierPage />} />
                    <Route path=":id/detail" element={<InventoryAdjustmentDetailPage />} />
                  </Route>

                  <Route path="purchases" element={<Outlet />}>
                    <Route path="suppliers" element={<Outlet />}>
                      <Route path="add" element={<AddEditSupplierPage />} />
                      <Route path=":id/edit" element={<AddEditSupplierPage />} />
                      <Route path=":id/detail" element={<SupplierDetail />} />
                      <Route path="" element={<SupplierMainPage />} />
                    </Route>
                    <Route path="expenses" element={<Outlet />}>
                      <Route path="" element={<OperationsRolesRoute />}>
                        <Route path="" element={<ExpensesMainPage />} />
                        <Route path=":id/edit" element={<AddExpense />} />
                        <Route path="add" element={<AddExpense />} />
                        <Route path=":id/detail" element={<ExpensesDetailPage />} />
                      </Route>
                    </Route>
                    <Route path="purchase-orders" element={<Outlet />}>
                      <Route
                        path=":purOrderId/createBill"
                        element={<AddEditBillPage />}
                      />
                      <Route path=":id/detail" element={<PurchaseOrderDetail />} />
                      <Route path=":id/edit" element={<AddEditPurchaseOrderPage />} />
                      <Route path="add" element={<AddEditPurchaseOrderPage />} />
                      <Route path="" element={<PurchaseOrderMainPage />} />
                    </Route>
                    <Route path="bills" element={<Outlet />}>
                      <Route path="" element={<OperationsRolesRoute />}>
                        <Route
                          path=":debitNoteId/createSupplierCredit"
                          element={<AddDebitNotePage />}
                        />
                        <Route
                          path=":debitNoteId/createSupplierCredit"
                          element={<AddDebitNotePage />}
                        />
                      </Route>
                      <Route path="add" element={<AddEditBillPage />} />
                      <Route path=":id/edit" element={<AddEditBillPage />} />
                      <Route path=":id/detail" element={<BillDetailPage />} />
                      <Route index element={<BillsMainPage />} />
                    </Route>
                    <Route path="payments-made" element={<Outlet />}>
                      <Route path="" element={<OperationsRolesRoute />}>
                        <Route path="" element={<PaymentsMadeMainPage />} />
                        <Route path=":id/detail" element={<PaymentMadeDetailPage />} />
                        <Route path=":id/edit" element={<AddPaymentMadePage />} />
                        <Route path="add" element={<AddPaymentMadePage />} />
                      </Route>
                    </Route>
                    <Route path="debit-notes" element={<Outlet />}>
                      <Route path="" element={<OperationsRolesRoute />}>
                        <Route path="" element={<DebitNotesMainPage />} />
                        <Route path=":id/edit" element={<AddDebitNotePage />} />
                        <Route path="add" element={<AddDebitNotePage />} />
                        <Route path=":id/detail" element={<DebitNoteDetailPage />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="sales" element={<Outlet />}>
                    <Route path="accounts" element={<Outlet />}>
                      <Route path=":id/detail" element={<AccountsDetailPage />} />
                      <Route path=":id/edit" element={<AddEditAccountsForm />} />
                      <Route path="add" element={<AddEditAccountsForm />} />
                      <Route path="" element={<AccountsMainPage />} />
                    </Route>

                    <Route path="customers" element={<Outlet />}>
                      <Route path=":id/detail" element={<CustomerDetailPage />} />
                      <Route path=":id/edit" element={<AddEditCustomersForm />} />
                      <Route path="add" element={<AddEditCustomersForm />} />
                      <Route path="" element={<CustomersMainPage />} />
                    </Route>
                    <Route path="quotations" element={<Outlet />}>
                      <Route
                        path=":quotationId/createInvoice"
                        element={<AddEditInvoicePage />}
                      />
                      <Route
                        path=":quotationId/createProInvoice"
                        element={<AddEditProInvoiceFrom />}
                      />
                      <Route path="add" element={<AddEditQuotationForm />} />
                      <Route path=":id/edit" element={<AddEditQuotationForm />} />
                      <Route path=":id/detail" element={<QuotationDetailPage />} />
                      <Route path="" index element={<QuotationsMainPage />} />
                    </Route>
                    <Route path="pro-invoices" element={<Outlet />}>
                      <Route
                        path=":proInvoiceId/createInvoice"
                        element={<AddEditInvoicePage />}
                      />
                      <Route path="add" element={<AddEditProInvoiceFrom />} />
                      <Route path=":id/edit" element={<AddEditProInvoiceFrom />} />
                      <Route path=":id/detail" element={<ProInvoiceDetailPage />} />
                      <Route path="" index element={<ProformaInvoiceMainPage />} />
                    </Route>
                    <Route path="invoices" element={<Outlet />}>
                      <Route path="" element={<OperationsRolesRoute />}>
                        <Route
                          path=":invoiceId/createInvoice"
                          element={<AddEditInvoicePage />}
                        />
                        <Route path="add" element={<AddEditInvoicePage />} />
                        <Route
                          path=":invoiceId/createCreditNotes"
                          element={<AddEditCreditNotePage />}
                        />
                        <Route path=":id/edit" element={<AddEditInvoicePage />} />
                      </Route>
                      <Route path=":id/detail" element={<InvoiceDetailPage />} />
                      <Route index element={<InvoiceMainPage />} />
                    </Route>

                    <Route path="credit-notes" element={<Outlet />}>
                      <Route path="" element={<OperationsRolesRoute />}>
                        <Route path="" element={<CreditNotesMainPage />} />
                        <Route path="add" element={<AddEditCreditNotePage />} />
                        <Route path=":id/edit" element={<AddEditCreditNotePage />} />
                        <Route path=":id/detail" element={<CreditNoteDetailPage />} />
                      </Route>
                    </Route>
                    <Route path="payments-received" element={<Outlet />}>
                      <Route path="" element={<OperationsRolesRoute />}>
                        <Route path="add" element={<AddEditPaymentReceivedPage />} />
                        <Route
                          path=":id/edit/"
                          element={<AddEditPaymentReceivedPage />}
                        />
                        <Route path=":id/detail" element={<PaymentReceivedDetail />} />
                        <Route path="" element={<PaymentReceivedMainPage />} />
                      </Route>
                    </Route>

                    <Route path="invoices" element={<InvoiceMainPage />} />
                  </Route>
                  <Route path="banking" element={<Outlet />}>
                    <Route path="" element={<OperationsRolesRoute />}>
                      <Route path="transaction" element={<BankAccountTransactions />} />
                      <Route path="import" element={<BankingImportStatementPage />} />
                      <Route path=":id/edit" element={<BankingFormPage />} />
                      <Route path="add" element={<BankingFormPage />} />
                      <Route path="" element={<BankingMainPage />} />
                    </Route>
                  </Route>
                  <Route path="items" element={<Outlet />}>
                    <Route index element={<ItemsMainPage />} />
                    <Route path="add" element={<AddEditItemForm />} />
                    <Route path=":id/edit" element={<AddEditItemForm />} />
                    <Route path=":id/detail" element={<ItemsDetailPage />} />
                  </Route>
                  <Route path="accountant" element={<Outlet />}>
                    <Route path="currencies" element={<Outlet />}>
                      <Route path="" element={<CurrenciesMainPage />} />
                      <Route path="add" element={<AddCurrenciesPage />} />
                      <Route path=":id/edit" element={<AddCurrenciesPage />} />

                    </Route>
                    <Route path="tax-payments" element={<TaxPayments />} />
                    <Route path="journals" element={<Outlet />}>
                      <Route path="add" element={<AddEditManualJournel />} />
                      <Route path=":id/edit" element={<AddEditManualJournel />} />
                      <Route path=":id/detail" element={<DetailManualJournel />} />
                      <Route path="" element={<ManualJournel />} />
                    </Route>
                    <Route path="chart-of-accounts" element={<Outlet />}>
                      <Route path="" element={<ChartOfAccounts />} />
                      <Route path=":id/detail" element={<ChartOfAccountDetail />} />
                    </Route>
                    ;
                    <Route path="transaction-locking" element={<Outlet />}>
                      <Route path="" element={<TransactionLocking />} />
                    </Route>
                    <Route
                      path="reports/account-transactions/:id/detail"
                      element={<AccountTransaction />}
                    />
                  </Route>
                  <Route path=":id/contacts" element={<Outlet />}>
                    <Route path="add" element={<ContactsAddEdit />} />
                    <Route path=":contactId/edit" element={<ContactsAddEdit />} />
                  </Route>
                  <Route path=":id/email" element={<SendEmail />} />
                </Route>
                <Route path="storage" index element={<StorageFilePage />} />
                <Route path="hr" element={<Outlet />}>
                  <Route path="asset-logs" element={<Outlet />}>
                    <Route path="" index element={<AssetLogsMainPage />} />
                    <Route path=":id/detail" index element={<AssetLogsDetailPage />} />
                  </Route>
                  <Route path="company-files" index element={<CompanyFilePage />} />
                  <Route path="company-profiles" element={<Outlet />}>
                    <Route path="" index element={<CompanyProfileMainPage />} />
                    <Route path="add" element={<CompanyProfilePage />} />
                    <Route path=":id/edit" element={<CompanyProfilePage />} />
                  </Route>
                  <Route path="employees" element={<Outlet />}>
                    <Route path="" index element={<HREmployees />} />
                    <Route path="add" element={<HRAddEmployees />} />
                    <Route path=":id/detail" element={<HREmployeesDetail />} />
                  </Route>

                  <Route path="company-holidays" element={<Outlet />}>
                    <Route path=":id/edit" element={<AddEditHolidayPage />} />
                    <Route path="add" element={<AddEditHolidayPage />} />
                    <Route path="" index element={<CompanyHolidaysMainPage />} />
                  </Route>

                  <Route path="leaves" element={<Outlet />}>
                    <Route path="" index element={<LeavesRequestsMainPage />} />
                  </Route>

                  <Route path="announcements" element={<Outlet />}>
                    <Route path=":id/detail" element={<AnnoucementsDetailPage />} />
                    <Route path="" index element={<AnnoucementsMainPage />} />
                  </Route>

                  <Route path="salaries" element={<Outlet />}>
                    <Route path="" index element={<MiscellaneousSalaries />} />
                  </Route>

                  <Route path="employee-doc-request" element={<Outlet />}>
                    <Route path="" index element={<DocRequestsMainPage />} />
                  </Route>
                </Route>

                <Route path="shifts" element={<Shifts />} />

                <Route path="attendance" element={<Outlet />}>
                  <Route index element={<Attendance />} />
                  <Route path="time-summary" element={<TimeSummary />} />
                  <Route path="productivity-report" element={<ProductivityReport />} />
                  <Route path="employee-attendance" element={<EmployeeAttendance />} />
                  <Route path="report-request" element={<ReportRequest />} />
                </Route>

                <Route path="miscellaneous" element={<Outlet />}>
                  <Route path="" element={<OperationsRolesRoute />}>
                    <Route path="salaries" element={<MiscellaneousSalaries />} />
                    <Route path="leaves" element={<MiscellaneousSalaries />} />
                  </Route>
                  <Route path="trash" element={<TrashDetailPage />} />
                  <Route path="internal-meetings" element={<MeetingsMainPage />} />
                </Route>

                <Route path="trash" element={<TrashDetailPage />} />

                <Route path="internal-meetings" element={<MeetingsMainPage />} />

                <Route path="/user/calender" element={<ManageActivities />} />

                <Route path="/manage-activities" element={<ManageActivities />} />
                <Route path="user/:id/profile/" element={<UserProfile />} />
                <Route path="ghulam-ali-test" element={<ErrorPage />} />
              </Route>
            </Route>

            <Route path="attendance/daily-tasks-report" element={<DailyReports />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
