/* eslint-disable no-unused-vars */
import React from 'react';
// import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Toolbar } from '@mui/material';

import useAuthInfo from 'customHooks/useAuthInfo';
import {
  topbarStyling,
  topbarRightStyling,
} from 'styles/mui/containers/topbar';
import { HR_CONTROLLER, HR_DEPT, HR_MANAGER, HR_STAFF } from 'utilities/constants';
import AnnoucmentSlider from 'containers/common/components/Announcements';
import SearchPopup from 'containers/common/components/SearchPopup';
import SearchForm from 'containers/common/components/SearchForm';
import TopbarProfile from './TopbarProfile';
import QuickCreateMenu from './QuickCreateMenu';
import RecentActivity from './RecentActivity';
import { topbarSearchInitialValues, topbarSearchModifiedValues } from '../utilities/data';
import CompanyMenu from './CompanyMenu';

function Topbar({ isOpen }) {
  const {
    allowedModules
  } = useAuthInfo();
  const isSalesSection = allowedModules?.find(module => (module?.section === 'Sales'));
  const isPurchaseSection = allowedModules?.find(module => (module?.section === 'Purchase'));

  return (

    <Toolbar
      sx={{ ...topbarStyling, backgroundColor: 'white', width: isOpen ? 'calc(100% - 120px)' : 'calc(100% - 246px)', }}
      className="d-flex justify-content-between align-items-center overflow-hidden"
    >

      <Box sx={{ maxWidth: '67%' }}>
        <AnnoucmentSlider />
      </Box>

      <Box className="d-flex align-items-center gap-2">
        {(isSalesSection || isPurchaseSection) && (
        <Box className="d-flex gap-1 justify-content-end align-items-center px-2 ml-4">
          <QuickCreateMenu />
          <RecentActivity />
          <SearchPopup isTopbarSelect>
            {toggle => (
              <SearchForm
                toggle={toggle}
                initValues={topbarSearchInitialValues}
                modifiedData={topbarSearchModifiedValues}
                resetValues={topbarSearchInitialValues}
                isTopbarSelect
              />
            )}
          </SearchPopup>
        </Box>
        )}

        <CompanyMenu />

        <Box sx={topbarRightStyling}>
          <TopbarProfile />
        </Box>
      </Box>
    </Toolbar>
  );
}

Topbar.propTypes = {
  // handleToggleSidebar: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Topbar;
