import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import moment from 'moment';
import { AccessTime } from '@mui/icons-material';
import CommonAttendanceCard from './CommonAttendanceCard';

function CheckInSince({ time, checkout, date }) {
  const [startTime, setStartTime] = useState(null);
  const [timeDiff, setTimeDiff] = useState(null);

  useEffect(() => {
    if (time && !checkout) {
      const [hours, minutes, seconds] = time.split(':');
      const startDate = moment(date).set({ hours, minutes, seconds });
      setStartTime(startDate);
    }
  }, [time]);

  function refreshClock() {
    setTimeDiff(moment.duration(moment().diff(startTime)));
  }

  useEffect(() => {
    let timerId;
    if (startTime && !checkout) {
      timerId = setInterval(refreshClock, 1000);
    }
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [startTime]);
  return (
    <CommonAttendanceCard
      title="Checked in Since"
      icon={<AccessTime color="primary" />}
      data={
        timeDiff && !checkout && startTime
          ? `${timeDiff.days() * 24 + timeDiff.hours()}h  ${timeDiff.minutes()}m`
          : '0h  0m'
      }
    />
  );
}

CheckInSince.propTypes = {
  time: propTypes.string,
  date: propTypes.string,
  checkout: propTypes.bool,
};

CheckInSince.defaultProps = {
  time: '',
  date: '',
  checkout: true,
};

export default CheckInSince;
