import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';
import { FormHelperText, Grid, Typography } from '@mui/material';

import { fieldStyles } from 'styles/containers/selectStyles';

function FormikMultiSelect({
  name,
  options,
  customClass,
  disabled,
  onChange,
  onBlur,
  label,
  isRow,
  isRequired,
  ...restProps
}) {
  const [field, meta] = useField(name || '');
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { value } = field;
  const { touched, error } = meta;

  const handleChange = selectedOptions => {
    const fieldValues = selectedOptions.map(item => item.value).filter(item => item.value !== '');

    const selectedValue = selectedOptions.map(item => item?.selectedValue);
    if (onChange) onChange({ value: fieldValues, selected: selectedValue });
    setFieldValue(name, fieldValues);
  };

  const handleBlur = event => {
    setFieldTouched(name, true);
    if (onBlur) onBlur(name, event.target.value);
  };

  const selectedOptions = options.filter(option => value.includes(option.value));

  return (
    <Grid spacing={1} className={customClass} id={name} container>
      {label && (
        <Grid item xl={isRow ? 3 : 12} lg={isRow ? 3 : 12} md={isRow ? 4 : 12} sm={12}>
          <Typography variant="body2" className={isRequired && 'required'}>
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item xl={isRow ? 9 : 12} lg={isRow ? 9 : 12} md={isRow ? 8 : 12} sm={12}>
        <Select
          {...restProps}
          id="select"
          options={options}
          isMulti
          onChange={handleChange}
          onBlur={handleBlur}
          value={selectedOptions || value}
          classNamePrefix="react-select"
          isDisabled={disabled}
          disabled={disabled}
          styles={fieldStyles}
          // menuIsOpen
        />
        {touched && error && (
          <FormHelperText>{error}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

FormikMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  menuPosition: PropTypes.string,
  menuPlacement: PropTypes.string,
  menuShouldBlockScroll: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isRow: PropTypes.bool,
};

FormikMultiSelect.defaultProps = {
  options: [],
  customClass: '',
  placeholder: '',
  disabled: false,
  menuPosition: 'absolute',
  menuPlacement: 'auto',
  menuShouldBlockScroll: false,
  formatOptionLabel: null,
  onChange: () => {},
  onBlur: () => {},
  label: null,
  isRequired: false,
  isRow: false,
};

export default FormikMultiSelect;
