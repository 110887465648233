import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCompanyBaseInfoQuery } from 'services/private/auth';
import { getCompanyDetail, onLoggedOut } from 'store/slices/authSlice';

import {
  COMPANY_DETAILS,
} from 'utilities/constants';

function useAuthInfo() {
  const dispatch = useDispatch();
  const {
    isAuthenticated,
  } = useSelector(state => state.auth);
  const companyDetail = useMemo(() => COMPANY_DETAILS, []);
  const { data: companyBaseInfoData, isSuccess, error } = useCompanyBaseInfoQuery();

  const {
    user,
    companyDetails
  } = useSelector(state => state.auth);
  const id = useMemo(() => user?.user?.id, [user]);
  const email = useMemo(() => user?.user?.email, [user]);

  const profile = useMemo(() => user?.user?.profile, [user]);
  const allowedSections = useMemo(() => user?.allowed_permission, [user]);
  const allowedModules = useMemo(() => user?.allowed_sections, [user]);
  const department = useMemo(() => user?.user?.profile?.department, [user]);
  useEffect(() => {
    if (isAuthenticated && JSON.parse(localStorage.getItem('companyDetail')) !== null) {
      dispatch(getCompanyDetail(JSON.parse(localStorage.getItem('companyDetail'))));
    } else if (isAuthenticated && isSuccess && companyBaseInfoData) {
      dispatch(getCompanyDetail(companyBaseInfoData));
    }
  }, [isSuccess, companyBaseInfoData, isAuthenticated]);
  useEffect(() => {
    if (error?.data?.detail === 'Invalid token.') {
      dispatch(onLoggedOut());
    }
  }, [error]);
  const isInventory = useMemo(() => companyDetails?.is_inventory_allowed, [companyDetails]);

  const company = useMemo(() => companyDetails, [companyDetails]);
  const stampSignature = useMemo(() => companyDetails && companyDetails?.stamp_signature, [companyDetails]);
  const stamp = useMemo(() => companyDetails && companyDetails.stamp_signature?.stamp, [companyDetails]);
  const signature = useMemo(() => companyDetails && companyDetails.stamp_signature?.signature, [companyDetails]);

  return {
    companyDetail,
    user,
    id,
    email,
    profile,
    department,
    isAuthenticated,
    allowedSections,
    allowedModules,
    isInventory,
    stamp,
    signature,
    stampSignature,
    company,
  };
}

export default useAuthInfo;
